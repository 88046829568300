import {createRouter, createWebHistory} from "vue-router";
import routes from "./routes";
import {getModal} from "@/functions/getModal";

const {closeModal} = getModal();

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {top: 0};
		}
	},
	routes,
});

router.beforeEach((to, from, next) => {
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);
	const nearestWithCanonical = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.canonical);
	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	} else if (previousNearestWithMeta) {
		document.title = previousNearestWithMeta.meta.title;
	}
	if (nearestWithCanonical) {
		const canonical = document.createElement("link");
		canonical.setAttribute("rel", "canonical");
		canonical.setAttribute("href", nearestWithCanonical.meta.canonical);
		document.head.append(canonical);
	}
	Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
		(el) => el.parentNode.removeChild(el),
	);
	if (!nearestWithMeta) return next();
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement("meta");
			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});
			tag.setAttribute("data-vue-router-controlled", "");
			return tag;
		})
		.forEach((tag) => document.head.appendChild(tag));
	next();

	let modals = document.querySelectorAll("[id$=Modal]");
	for (let i = 0; i < modals.length; i++) {
		let modalName = modals[i].id.replace("Modal", "");
		closeModal(modalName);
	}
});

export default router;
