import {getDate} from "@/functions/getDate"

const currentDate = getDate()
const oneDay = 1000 * 60 * 60 * 24
let isShowPriceSlash = false
let isShowProPriceSlash = false

export const getPriceSlashData = async (prices) => {
  let priceChangeDateString = prices.midYearPriceEffectiveDate
  let priceChangeDate = new Date(priceChangeDateString)
  let priceChangeStartDate = new Date(priceChangeDate - 15 * oneDay)
  let proPriceChangeDateString = prices.mid_year_pro_support_effective_date
  let proPriceChangeDate = new Date(proPriceChangeDateString)
  let proPriceChangeStartDate = new Date(proPriceChangeDate - 15 * oneDay)

  // set slashed pricing for state price
  if (currentDate >= priceChangeStartDate && currentDate < priceChangeDate) {
    isShowPriceSlash = true
  }
  if (prices.price !== prices.midYearPrice && isShowPriceSlash) {
    prices.slashedPrice = '<span class="text-decoration-line-through">' + prices.midYearPrice + "</span> $" + prices.price
  } else if (currentDate >= priceChangeStartDate) {
    prices.slashedPrice = prices.midYearPrice
    prices.price = prices.midYearPrice
  } else {
    prices.slashedPrice = prices.price
  }

  // set slashed pricing for pro pricing
  if (currentDate >= proPriceChangeStartDate && currentDate < proPriceChangeDate) {
    isShowProPriceSlash = true
  }
  if (prices.pro_support_price !== prices.mid_year_pro_support_price && isShowProPriceSlash) {
    prices.slashed_pro_support_price = '<span class="text-decoration-line-through">$' + prices.mid_year_pro_support_price + "</span> $" + prices.pro_support_price
  } else if (currentDate >= proPriceChangeStartDate) {
    prices.slashed_pro_support_price = "$" + prices.mid_year_pro_support_price
    prices.pro_support_price = prices.mid_year_pro_support_price
  } else {
    prices.slashed_pro_support_price = "$" + prices.pro_support_price
  }

  return prices
}
