<template>
  <div class="mobile-menu d-flex flex-column flex-row-auto" id="mobile-menu">
    <div class="mobile-menu-wrapper flex-column-fluid">
      <div
        class="mobile-menu-container mt-4 pb-4"
        data-menu-vertical="1"
        data-menu-scroll="1"
        data-menu-dropdown-timeout="500"
      >
        <ul class="mobile-menu-nav accordion" id="mobileAccordion">
          <li class="mobile-menu-item accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <span class="mobile-menu-title"
                ><span class="menu-text">Pricing & Products</span></span
              >
            </button>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              data-bs-parent="#mobileAccordion"
            >
              <div class="accordion-body">
                <router-link
                  class="mobile-menu-link"
                  to="/software"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">Our Free Federal</span></router-link
                >
                <router-link
                  class="mobile-menu-link"
                  to="/deluxe"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">Deluxe</span></router-link
                >
                <router-link
                  class="mobile-menu-link"
                  to="/pro"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">Pro Support</span></router-link
                >
                <router-link
                  class="mobile-menu-link"
                  to="/audit"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">Audit Defense</span></router-link
                >
                <router-link
                  class="mobile-menu-link"
                  to="/extension"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">Tax Extensions</span></router-link
                >
                <router-link
                  class="mobile-menu-link"
                  to="/prior-year"
                  @click="toggleMobileMenu"
                  ><span class="menu-text"
                    >Prior Year Returns</span
                  ></router-link
                >
              </div>
            </div>
          </li>
          <li class="mobile-menu-item accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <span class="mobile-menu-title"
                ><span class="menu-text">Support</span></span
              >
            </button>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              data-bs-parent="#mobileAccordion"
            >
              <div class="accordion-body">
                <router-link
                  class="mobile-menu-link"
                  to="/support"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">Customer Support</span></router-link
                >
                <a
                  class="mobile-menu-link"
                  :href="constants.signInHelpUrl"
                  @click="toggleMobileMenu"
                  ><span class="menu-text"
                    >Forgot Username or Password?</span
                  ></a
                >
                <a
                  class="mobile-menu-link"
                  :href="constants.contactSupportUrl"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">My Support Issues</span></a
                >
              </div>
            </div>
          </li>
          <li class="mobile-menu-item accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <span class="mobile-menu-title"
                ><span class="menu-text">Prior Year</span></span
              >
            </button>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              data-bs-parent="#mobileAccordion"
            >
				<div class="accordion-body">
					<router-link class="mobile-menu-link" to="/prior-year" @click="toggleMobileMenu"><span class="menu-text">All Years 2017-2023</span>
					</router-link>
					<router-link class="mobile-menu-link" to="/prior-year#2023" @click="toggleMobileMenu"><span class="menu-text">File 2023 Tax Return</span>
					</router-link>
					<router-link class="mobile-menu-link" to="/prior-year#2022" @click="toggleMobileMenu"><span class="menu-text">File 2022 Tax Return</span>
					</router-link>
					<router-link class="mobile-menu-link" to="/prior-year#2021" @click="toggleMobileMenu"><span class="menu-text">File 2021 Tax Return</span>
					</router-link>
					<a :href="constants.printUrl" class="mobile-menu-link" @click="toggleMobileMenu"><span class="menu-text">View My Prior Year Return(s)</span></a>
				</div>
            </div>
          </li>
          <li class="mobile-menu-item accordion-item">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              <span class="mobile-menu-title"
                ><span class="menu-text">After You File</span></span
              >
            </button>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              data-bs-parent="#mobileAccordion"
            >
              <div class="accordion-body">
                <a
                  class="mobile-menu-link"
                  :href="constants.checkUrl"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">Check E-File Status</span></a
                >
                <a
                  class="mobile-menu-link"
                  :href="constants.printUrl"
                  @click="toggleMobileMenu"
                  ><span class="menu-text">Print / Download My Return</span></a
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    class="mobile-menu-overlay"
    id="mobile-menu-overlay"
    @click="toggleMobileMenu"
  ></div>
</template>

<script setup>
import {onMounted, watch} from "vue"
import {useRoute} from "vue-router"
import {constants} from "@/functions/constants"
import {mobileMenuHandler} from "@/functions/menuHandlerMobile"

const { closeMobileMenu, toggleMobileMenu } = mobileMenuHandler();
const route = useRoute();

onMounted(() => {
  window.addEventListener("DOMContentLoaded", closeMobileMenu);
  window.addEventListener("resize", closeMobileMenu);
  document.addEventListener("keydown", (evt) => {
    if (evt.key === "Escape") {
      closeMobileMenu();
    }
  });

  watch(route, () => {
    closeMobileMenu();
  });
});
</script>
