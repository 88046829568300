import {getPriorYears} from "@/functions/getPriorYears"

export const getPriorYearPriceData = async (prices) => {
	const priorYears = await getPriorYears()

	if (prices.prior_year_price_1) {
		for (const priorYear of priorYears) {
			prices[`prior_year_price_${priorYear}`] = prices[`prior_year_price_${priorYears.indexOf(priorYear) + 1}`]
			delete prices[`prior_year_price_${priorYears.indexOf(priorYear) + 1}`]
		}
	}
	prices.prior_year_price = prices[`prior_year_price_${priorYears[2]}`]
	return prices
}
