import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import {createApp} from "vue"
import VueMatomo from "vue-matomo"
import App from "./App.vue"
import router from "./router"
import {matomo} from "@/functions/matomo"

createApp(App)
.use(router)
.use(VueMatomo, {
	host: "https://analytics.express1040.com",
	siteId: 3,
	router: router
	// trackInteraction: (to, from) => {
	// 	if (!from) {return true}
	// 	return to.path !== from.path || to.hash !== from.hash
	// }
})
.mount("#app")

matomo()
