let allowedParameters = [
	"utm_source",
	"utm_campaign",
	"utm_medium",
	"utm_content",
	"utm_term",
	"mtm_group",
	"CMP",
	"cmp",
	"DX",
	"dx",
	"AID",
	"PID",
	"SID",
	"irclickid",
	"sharedid",
	"irpid",
	"irgwc",
	"share"
]

export const saveUrlParams = () => {
	const paramList = new URLSearchParams(window.location.search)
	paramList.forEach((value, key) => {
		if (allowedParameters.includes(key)) {
			sessionStorage.setItem(key, value)
		}
	})
}

export const constructParams = () => {
	let params = "PRMPT"

	allowedParameters.forEach(param => {
		if (sessionStorage.getItem(param)) {
			params += "&" + param + "=" + sessionStorage.getItem(param)
		}
	})
	params = "&" + params
	return params
}
